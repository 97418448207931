.Reasons{
    background-color: #10141e;
    padding: 0 2rem;
    padding-top: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 30rem;
}

.left-r{
    z-index: 1;
    opacity: 0.1;
    flex: 1 1;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 1rem;
    grid-auto-rows: 1fr; 
    position: absolute;   
    top: 2rem;
}

.left-r>img{
    object-fit: cover;
}

.left-r>:nth-child(1){
    width: 12rem;
    grid-row: 1/3;
    height: 28rem;
}

.left-r>:nth-child(2){
    width: auto;
    height: 16rem;
    grid-column: 2/4;
}

.left-r>:nth-child(3){
    width: 14rem;
    grid-column: 2/3;
    grid-row: 2;
}

.left-r>:nth-child(4){
    width: 10rem;
    grid-row: 2;
    grid-column: 3/4;
    height: 11.2rem;
}

.right-r{
    z-index: 2;
    flex: 1 1;
    gap: 2rem;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 3rem;
}

.right-r>span{
    font-weight: bold;
    font-size: x-large;
    color: white;
}

.right-r>div{
    font-size: 3rem;
    color: white;
    font-weight: bold;
}

.details-r{
    color: white;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.details-r>div{
    color: white;
    display: flex;
    font-size: 1rem;
    gap: 1rem;
}

.details-r>div>img{
    width: 2rem;
    height: 2rem;
}

.partners{
    display: flex;
    gap: 1rem;
}

.partners>img{
    width: 2.5rem;
}

@media screen and (max-width:768px) {
    
    .Reasons{
        flex-direction: column;
        height: 80vh;
        width: 80vw;
    }

    .left-r{
        grid-auto-rows: auto;
        overflow: hidden;
    }

    .left-r>:nth-child(1){
        width: 5rem;
        height: 15rem;
    }

    .left-r>:nth-child(2){
        width: 15rem;
        height: 10rem;
    }

    .left-r>:nth-child(3){
        width: 7rem;
    }

    .left-r>:nth-child(4){
        width: 7rem;
        height: 6rem;
    }

    .right-r{
        margin-left: 1rem;
    }

    .right-r>span{
        font-weight: bold;
        font-size: large;
        color: white;
    }
    
    .right-r>div{
        font-size: 2.5rem;
        color: white;
        font-weight: bold;
    }
    
    .details-r{
        color: white;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    
    .details-r>div{
        color: white;
        display: flex;
        font-size: .8rem;
        gap: 1rem;
    }
    
    .details-r>div>img{
        width: 1rem;
        height: 1rem;
    }
    
    .partners{
        display: flex;
        gap: 1rem;
    }
    
    .partners>img{
        width: 2rem;
    }
}