/* make variables */
:root {
  --lightgray: #D9D9D9;
  --gray: #9c9c9c;
  --orange: #f48915;
  --darkGrey: #464D53;
  --caloryCard: #656565;
  --planCard: linear-gradient(210.41deg,#184d68 1.14%, #211e33 100.75%);
  --appColor:  #3c3f45;
}

.App {
  mix-blend-mode: overlay;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
::-webkit-scrollbar{
  display: none;
}

.stroke-text{
  color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;   
}

.btn{
  background-color: white;
  padding: 0.5rem;
  font-weight: bold;
  border: 4px solid transparent;
  transition: 300ms all;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn:hover{
  cursor: pointer;
}